import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, Tooltip, IconButton, Grid, Box } from '@mui/material';
import VocabularyOverview from '../components/VocabularyOverview';
import VocabularyDetails from '../components/VocabularyDetails';
import { api, SERVER_BASE_URL } from '../services/api';
import { saveAs } from 'file-saver';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const VocabularyPage = () => {
    const [selectedVocabulary, setSelectedVocabulary] = useState('');
    const [token, setToken] = useState('');
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadResult, setUploadResult] = useState(null);
    const [loadVocabulary, setLoadVocabulary] = useState(true);

    useEffect(() => {
        const savedToken = document.cookie.split('; ').find(row => row.startsWith('token='));
        if (savedToken) {
            setToken(savedToken.split('=')[1]);
        }
    }, []);

    useEffect(() => {
        if (token) {
            setLoadVocabulary(true);
        }
    }, [token]);

    const handleBackToOverview = () => {
        setSelectedVocabulary('');
        setLoadVocabulary(true); // Re-enable loading the overview
    };

    const handleUploadDialogOpen = () => {
        setUploadDialogOpen(true);
    };

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
        setUploadFile(null);
        setUploadResult(null);
    };

    const handleFileChange = (event) => {
        setUploadFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!uploadFile) {
            setUploadResult("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadFile);

        try {
            const response = await api.uploadVocabulary(token, formData);
            setUploadResult(response.data.message);
            setLoadVocabulary(false); // To refresh the overview after upload
        } catch (error) {
            console.error('Failed to upload vocabulary', error);
            setUploadResult("Failed to upload vocabulary. Please try again later.");
        }
    };

    const handleDownloadTemplate = () => {
        saveAs(`${SERVER_BASE_URL}/vocabulary_template.xlsx`, "vocabulary_template.xlsx");
    };

    return (
        <Container style={{ marginTop: '20px', width: '100%' }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" gutterBottom>Vocabulary Overview</Typography>
                </Grid>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <Tooltip title="After uploading your list, you can go to GPT and review the uploaded vocabulary list.">
                            <IconButton>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleUploadDialogOpen}
                            style={{ marginLeft: '10px' }}
                        >
                            Upload Vocabulary
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={2}>
                {loadVocabulary && !selectedVocabulary && (
                    <VocabularyOverview token={token} onSelectVocabulary={setSelectedVocabulary} />
                )}
                {selectedVocabulary && (
                    <VocabularyDetails token={token} vocabulary={selectedVocabulary} onBackToOverview={handleBackToOverview} />
                )}
            </Box>
            <Dialog open={uploadDialogOpen} onClose={handleUploadDialogClose}>
                <DialogTitle>Upload Vocabulary</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To upload a vocabulary, please select an Excel file with the following columns: word, vocabulary, summary, note. You can download a template below.
                    </DialogContentText>
                    <input type="file" accept=".xlsx" onChange={handleFileChange} />
                    {uploadResult && <Alert severity="info" style={{ marginTop: '20px' }}>{uploadResult}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDownloadTemplate} color="primary">
                        Download Template
                    </Button>
                    <Button onClick={handleUploadDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpload} color="primary">
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default VocabularyPage;
