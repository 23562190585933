import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Tooltip, Legend, TimeScale } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { api } from '../services/api';
import 'chartjs-adapter-date-fns';

// 注册 chart.js 组件
Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    TimeScale,
    Tooltip,
    Legend
);

const Statistics = ({ token }) => {
    const [activityData, setActivityData] = useState(null);
    const [reviewData, setReviewData] = useState(null);
    const [vocabCountData, setVocabCountData] = useState(null);
    const [chapterData, setChapterData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (token) {
            fetchStatistics(token);
        }
    }, [token]);

    const fetchStatistics = async (token) => {
        try {
            const response = await api.getStatistics(token);
            if (response.data) {
                setActivityData(formatActivityData(response.data.activities));
                setReviewData(formatReviewData(response.data.reviews));
                setVocabCountData(formatVocabCountData(response.data.vocabCounts));
                setChapterData(formatChapterData(response.data.chapters));
            } else {
                setError('No data found');
            }
        } catch (error) {
            console.error('Failed to fetch statistics', error);
            setError('Failed to fetch statistics. Please try again later.');
        }
    };

    const formatActivityData = (data) => {
        if (!data || !Array.isArray(data)) return { labels: [], datasets: [] };
        const groupedData = data.reduce((acc, item) => {
            const date = item.date.split('T')[0];
            if (!acc[date]) acc[date] = {};
            if (!acc[date][item.action]) acc[date][item.action] = 0;
            acc[date][item.action] += item.count;
            return acc;
        }, {});

        const labels = Object.keys(groupedData);
        const actions = Array.from(new Set(data.map(item => item.action)));

        const datasets = actions.map(action => ({
            label: action,
            data: labels.map(date => groupedData[date][action] || 0),
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
        }));

        return { labels, datasets };
    };

    const formatReviewData = (data) => {
        if (!data || !Array.isArray(data)) return { labels: [], datasets: [] };
        const groupedData = data.reduce((acc, item) => {
            const date = item.date.split('T')[0];
            if (!acc[date]) acc[date] = 0;
            acc[date] += item.count;
            return acc;
        }, {});

        const labels = Object.keys(groupedData);
        const datasets = [
            {
                label: 'Word Reviews',
                data: labels.map(date => groupedData[date]),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            }
        ];

        return { labels, datasets };
    };

    const formatVocabCountData = (data) => {
        if (!data || !Array.isArray(data)) return { labels: [], datasets: [] };
        return {
            labels: data.map(item => item.vocabulary),
            datasets: [
                {
                    label: 'Vocabulary Count',
                    data: data.map(item => item.count),
                    backgroundColor: data.map(() => `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`),
                },
            ],
        };
    };

    const formatChapterData = (data) => {
        if (!data || !Array.isArray(data)) return { labels: [], datasets: [] };
        return {
            labels: data.map(item => item.chapter),
            datasets: [
                {
                    label: 'Chapters',
                    data: data.map(item => item.progress),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                },
            ],
        };
    };

    if (error) {
        return (
            <Container>
                <Typography variant="h4" gutterBottom>
                    Statistics
                </Typography>
                <Box my={4}>
                    <Typography variant="h6" color="error">{error}</Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Statistics
            </Typography>
            <Box my={4}>
                <Typography variant="h6">User Activities</Typography>
                {activityData ? <Bar data={activityData} options={{ scales: { x: { type: 'time', time: { unit: 'day' } } } }} /> : <Typography>Loading...</Typography>}
            </Box>
            <Box my={4}>
                <Typography variant="h6">Word Review Counts</Typography>
                {reviewData ? <Bar data={reviewData} options={{ scales: { x: { type: 'time', time: { unit: 'day' } } } }} /> : <Typography>Loading...</Typography>}
            </Box>
            <Box my={4}>
                <Typography variant="h6">User Vocabulary Counts</Typography>
                {vocabCountData ? <Doughnut data={vocabCountData} /> : <Typography>Loading...</Typography>}
            </Box>
            <Box my={4}>
                <Typography variant="h6">User Comprehensive Learning Progress</Typography>
                {chapterData ? <Bar data={chapterData} options={{ indexAxis: 'y' }} /> : <Typography>Loading...</Typography>}
            </Box>
        </Container>
    );
};

export default Statistics;
