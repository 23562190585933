import React, { useState, useEffect } from 'react';
import {
    Button,
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    CardMedia,
    IconButton,
    Snackbar,
    Alert,
    Autocomplete
} from '@mui/material';
import { api } from '../services/api';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Scatter } from 'recharts';
import ChartIcon from '@mui/icons-material/ShowChart';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteIcon from '@mui/icons-material/Delete';

const VocabularyDetails = ({ token, vocabulary, onBackToOverview }) => {
    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedWord, setSelectedWord] = useState(null);
    const [note, setNote] = useState('');
    const [chartOpen, setChartOpen] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [newVocabulary, setNewVocabulary] = useState('');
    const [showBack, setShowBack] = useState(false);
    const [vocabularies, setVocabularies] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const fetchWords = async () => {
            try {
                const response = await api.getWordsForVocabulary(token, vocabulary);
                setWords(response.data);
                setLoading(false);
            } catch (error) {
                console.error(`Failed to fetch words for ${vocabulary}`, error);
                setLoading(false);
            }
        };

        const fetchVocabularies = async () => {
            try {
                const response = await api.getVocabularies(token);
                setVocabularies(response.data);
            } catch (error) {
                console.error('Failed to fetch vocabularies', error);
            }
        };

        fetchWords();
        fetchVocabularies();
    }, [token, vocabulary]);

    const handleWordClick = (word) => {
        setSelectedWord(word);
        setNote(word.note || '');
        setShowBack(false); // Reset to show front side when clicking a new word
    };

    const handleCloseDialog = () => {
        setSelectedWord(null);
    };

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleSaveNote = async () => {
        try {
            const noteEntry = { word: selectedWord.word, note };
            await api.addNoteToWord(token, vocabulary, noteEntry);
            setWords(words.map(word => word.word === selectedWord.word ? { ...word, note } : word));
            setSelectedWord(null);
            setSnackbarMessage('Note saved successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Failed to update note', error);
        }
    };

    const getReviewData = (word) => {
        const data = [
            { name: 'Added', date: new Date(word.date_added).toLocaleDateString() }
        ];
        if (word.review_times) {
            word.review_times.forEach((review, index) => {
                data.push({ name: `Review ${index + 1}`, date: new Date(review).toLocaleDateString() });
            });
        }
        return data.sort((a, b) => new Date(a.date) - new Date(b.date)); // Ensure data is sorted in ascending order
    };

    const handleOpenChart = () => {
        setChartOpen(true);
    };

    const handleCloseChart = () => {
        setChartOpen(false);
    };

    const handleUploadClick = () => {
        setUploadOpen(true);
    };

    const handleCloseUpload = () => {
        setUploadOpen(false);
        setImageFile(null);
    };

    const handleImageChange = (event) => {
        if (event.target.files.length > 0) {
            setImageFile(event.target.files[0]);
        }
    };

    const handleUploadImage = async () => {
        if (!imageFile) return;

        const formData = new FormData();
        formData.append('token', token);
        formData.append('word', selectedWord.word);
        formData.append('file', imageFile);

        try {
            const response = await api.uploadImage(formData);
            const updatedWord = { ...selectedWord, image_url: response.data.image_url };
            setWords(words.map(word => word.word === selectedWord.word ? updatedWord : word));
            setSelectedWord(updatedWord);
            handleCloseUpload();
        } catch (error) {
            console.error('Failed to upload image', error);
        }
    };

    const handleMarkAsMastered = async () => {
        try {
            await api.markWordAsMastered(token, vocabulary, selectedWord.word);
            setWords(words.map(word => word.word === selectedWord.word ? { ...word, mastered: true } : word));
            setSelectedWord(null);
            setSnackbarMessage('Word marked as mastered');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Failed to mark word as mastered', error);
        }
    };

    const handleUnmarkAsMastered = async () => {
        try {
            await api.unmarkWordAsMastered(token, vocabulary, selectedWord.word);
            setWords(words.map(word => word.word === selectedWord.word ? { ...word, mastered: false } : word));
            setSelectedWord(null);
            setSnackbarMessage('Word unmarked as mastered');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Failed to unmark word as mastered', error);
        }
    };

    const handleDeleteWord = async (word) => {
        try {
            await api.deleteWord(token, word);
            setWords(words.filter(w => w.word !== word));
            setSelectedWord(null);
            setSnackbarMessage('Word deleted successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Failed to delete word', error);
        }
    };

    const handleMoveWord = async (word, newVocabulary) => {
        try {
            await api.moveWord(token, word, { new_vocabulary: newVocabulary });
            setWords(words.filter(w => w.word !== word));
            setSelectedWord(null);
            setSnackbarMessage('Word moved successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Failed to move word', error);
        }
    };

    const handleFlipCard = () => {
        setShowBack(!showBack);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>{vocabulary}</Typography>
            <Button variant="outlined" onClick={onBackToOverview} style={{ marginBottom: '20px' }}>
                Back to Overview
            </Button>
            {loading ? (
                <CircularProgress />
            ) : (
                <Grid container spacing={3}>
                    {words.map((word, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card 
                                style={{ 
                                    height: '200px', 
                                    overflow: 'hidden', 
                                    cursor: 'pointer', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'space-between', 
                                    backgroundColor: word.mastered ? 'lightgreen' : 'white' 
                                }} 
                                onClick={() => handleWordClick(word)}
                                onMouseOver={e => e.currentTarget.style.boxShadow = '0 0 11px rgba(33,33,33,.2)'}
                                onMouseOut={e => e.currentTarget.style.boxShadow = 'none'}
                            >
                                <CardContent>
                                    <Typography variant="h6" component="div" gutterBottom noWrap>
                                        {word.word}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" noWrap>
                                        {word.summary}
                                    </Typography>
                                </CardContent>
                                {word.image_url && (
                                    <CardMedia
                                        component="img"
                                        image={`https://vocabank.org/${word.image_url}`}
                                        alt={word.word}
                                        style={{ height: '100px', objectFit: 'contain' }}
                                    />
                                )}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
            {selectedWord && (
                <Dialog open={Boolean(selectedWord)} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogTitle>
                        Edit Note for {selectedWord.word}
                        <IconButton aria-label="close" onClick={handleCloseDialog} style={{ position: 'absolute', right: 8, top: 8 }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        {!showBack ? (
                            <>
                                <Typography gutterBottom>
                                    <strong>Word:</strong> {selectedWord.word}
                                </Typography>
                                <Typography gutterBottom>
                                    <strong>Summary:</strong> {selectedWord.summary}
                                </Typography>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Note"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={note}
                                    onChange={handleNoteChange}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<ChartIcon />}
                                    onClick={handleOpenChart}
                                    style={{ marginTop: '20px', marginRight: '10px' }}
                                >
                                    Show Review History
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddPhotoAlternateIcon />}
                                    onClick={handleUploadClick}
                                    style={{ marginTop: '20px', marginRight: '10px' }}
                                >
                                    Upload Image
                                </Button>
                                {selectedWord.mastered ? (
                                    <Button
                                        variant="contained"
                                        color="error"
                                        startIcon={<HighlightOffIcon />}
                                        onClick={handleUnmarkAsMastered}
                                        style={{ marginTop: '20px', marginRight: '10px' }}
                                    >
                                        Unmark as Mastered
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<CheckCircleIcon />}
                                        onClick={handleMarkAsMastered}
                                        style={{ marginTop: '20px', marginRight: '10px' }}
                                    >
                                        Mark as Mastered
                                    </Button>
                                )}
                                {selectedWord.image_url && (
                                    <div style={{ marginTop: '20px' }}>
                                        <Typography variant="h6">Image:</Typography>
                                        <img
                                            src={`https://vocabank.org/${selectedWord.image_url}`}
                                            alt={selectedWord.word}
                                            style={{ width: '100%', cursor: 'pointer' }}
                                            onClick={() => window.open(`https://vocabank.org/${selectedWord.image_url}`, '_blank')}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <Typography gutterBottom>
                                    <strong>Word:</strong> {selectedWord.word}
                                </Typography>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Back Side Note"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={note}
                                    onChange={handleNoteChange}
                                />
                            </>
                        )}
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFlipCard}
                            style={{ marginTop: '20px', marginRight: '10px' }}
                        >
                            {showBack ? 'Show Front' : 'Show Back'}
                        </Button> */}
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleDeleteWord(selectedWord.word)}
                            style={{ marginTop: '20px', marginRight: '10px' }}
                        >
                            Delete Word
                        </Button>
                        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                            Move to Vocabulary
                        </Typography>
                        <Autocomplete
                            freeSolo
                            options={vocabularies.map((option) => option.vocabulary)}
                            value={newVocabulary}
                            onChange={(event, newValue) => setNewVocabulary(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Enter or select a vocabulary"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => setNewVocabulary(e.target.value)}
                                />
                            )}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleMoveWord(selectedWord.word, newVocabulary)}
                            style={{ marginBottom: '20px', marginTop: '10px' }}
                        >
                            Move Word
                        </Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveNote} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {uploadOpen && (
                <Dialog open={uploadOpen} onClose={handleCloseUpload} maxWidth="sm" fullWidth>
                    <DialogTitle>Upload Image for {selectedWord.word}</DialogTitle>
                    <DialogContent>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-file"
                            type="file"
                            onChange={handleImageChange}
                        />
                        <label htmlFor="upload-file">
                            <Button variant="contained" component="span">
                                Choose File
                            </Button>
                        </label>
                        {imageFile && (
                            <Typography variant="body2" style={{ marginTop: '10px' }}>
                                {imageFile.name}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUpload} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUploadImage} color="primary" disabled={!imageFile}>
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {selectedWord && (
                <Dialog open={chartOpen} onClose={handleCloseChart} maxWidth="md" fullWidth>
                    <DialogTitle>Review History for {selectedWord.word}</DialogTitle>
                    <DialogContent>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={getReviewData(selectedWord)}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" tickFormatter={(tick) => tick.split(' ')[0]} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="date" stroke="#8884d8" activeDot={{ r: 8 }} />
                                {getReviewData(selectedWord).map((entry, index) => (
                                    <Scatter key={index} data={[entry]} fill="green" shape="circle" r={8} />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseChart} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default VocabularyDetails;
