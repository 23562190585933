import React from 'react';
import { Container, Box, Button, Typography, Grid, Card, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
    typography: {
        h2: {
            fontWeight: 700,
        },
        h5: {
            fontWeight: 500,
        },
    },
});

const backgroundStyle = {
    backgroundImage: `url('./background.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    color: '#fff',
    position: 'relative',
};

const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
};

const contentStyle = {
    position: 'relative',
    zIndex: 2,
    color: '#fff',
    padding: '20px',
};

const HomePage = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={backgroundStyle}>
                <div style={overlayStyle}></div>
                <Container style={contentStyle}>
                    <Box py={6}>
                        <Typography variant="h2" align="center" gutterBottom>
                            Welcome to Vocabulary Builder
                        </Typography>
                        <Typography variant="h5" align="center" paragraph>
                            Your ultimate assistant for learning and storing vocabulary efficiently.
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Create Tokens
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            Easily create tokens to manage your vocabulary.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Start Learning
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            Interact with GPTs and save all your learned words and expressions by category.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Reliable Memory
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            We resolved the issue of GPT's bad memory. All your learning progress is saved securely.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Custom Vocabulary Bank
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            Customize your vocabulary bank and review your saved words anytime, anywhere.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Box textAlign="center" mt={4}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        href="https://chatgpt.com/g/g-trViDlkIa-vocabulary-builder-language-learning"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Start Learning Now
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        href="/dashboard"
                                    >
                                        Go to Dashboard
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </div>
            <Box mt={4} mb={2}>
                <Typography variant="body2" color="textSecondary" align="center">
                    © 2024 Vocabank. All rights reserved.
                </Typography>
            </Box>
        </ThemeProvider>
    );
};

export default HomePage;
