import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, ListItem, ListItemText, CircularProgress, Alert, Box } from '@mui/material';
import { api } from '../services/api';

const VocabularyOverview = ({ token, onSelectVocabulary }) => {
    const [vocabularies, setVocabularies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (token) {
            fetchVocabularies();
        }
    }, [token]);

    const fetchVocabularies = async () => {
        setLoading(true);
        setError(null);  // Reset error state on new token
        try {
            const response = await api.getVocabularies(token);
            setVocabularies(response.data);
            setLoading(false);
        } catch (error) {
            console.error('No vocabularies found for this token', error);
            setError(error);
            setLoading(false);
        }
    };

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">Failed to fetch vocabularies. Please try again later.</Alert>
            ) : (
                <Grid container spacing={2} justifyContent="center">
                    {vocabularies.length === 0 ? (
                        <Typography variant="body1" style={{ textAlign: 'center', padding: '20px' }}>
                            No vocabularies found.
                        </Typography>
                    ) : (
                        vocabularies.map((vocab, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                <Box sx={{ bgcolor: '#f7f7f7', p: 2, borderRadius: '10px', textAlign: 'center' }}>
                                    <ListItem button onClick={() => onSelectVocabulary(vocab.vocabulary)}>
                                        <ListItemText primary={vocab.vocabulary} />
                                    </ListItem>
                                </Box>
                            </Grid>
                        ))
                    )}
                </Grid>
            )}
        </Container>
    );
};

export default VocabularyOverview;
