import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, List, ListItem, ListItemText, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { api } from '../services/api';

const NotePage = ({ token }) => {
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [noteDetail, setNoteDetail] = useState('');

    useEffect(() => {
        if (token) {
            fetchNotes();
        }
    }, [token, page, search]);

    const fetchNotes = async () => {
        setLoading(true);
        try {
            const response = await api.getNotes(token, page, limit, search);
            setNotes(response.data.notes);
            setTotal(response.data.total);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch notes', error);
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearch = () => {
        setPage(1);
        fetchNotes();
    };

    const handleOpenDialog = (note) => {
        setNoteDetail(note.detail);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNoteDetail('');
    };

    const handleLoadMore = () => {
        setPage(page + 1);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Notes
            </Typography>
            <TextField
                label="Search"
                variant="outlined"
                fullWidth
                value={search}
                onChange={handleSearchChange}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            />
            <Button onClick={handleSearch} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                Search
            </Button>
            {loading ? (
                <CircularProgress />
            ) : (
                <List>
                    {notes.map((note) => (
                        <ListItem button key={note.id} onClick={() => handleOpenDialog(note)}>
                            <ListItemText primary={note.title} secondary={new Date(note.timestamp).toLocaleString()} />
                        </ListItem>
                    ))}
                </List>
            )}
            {page * limit < total && (
                <Button onClick={handleLoadMore} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                    Load More
                </Button>
            )}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Note Detail</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {noteDetail}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default NotePage;
