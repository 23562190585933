import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';

const Dashboard = () => {
    const [token, setToken] = useState('');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const savedToken = document.cookie.split('; ').find(row => row.startsWith('token='));
        if (savedToken) {
            const tokenValue = savedToken.split('=')[1];
            validateToken(tokenValue);
        } else {
            setOpen(true);
        }
    }, []);

    const validateToken = async (token) => {
        try {
            const response = await api.checkToken(token);
            if (response.data.message === "Token exists and is not expired") {
                setToken(token);
                document.cookie = `token=${token}; path=/`;
                setOpen(false);
                navigate('/dashboard/main');
            } else if (response.data.message === "Token exists but expired") {
                setToken(token);
                document.cookie = `token=${token}; path=/`;
                setOpen(false);
                navigate('/dashboard/main'); // Redirect to dashboard and show the recharge option
            } else {
                setError('Invalid token. Please enter a valid token.');
                setOpen(true);
            }
        } catch (error) {
            setError('Error validating token. Please try again.');
            setOpen(true);
        }
    };

    const handleTokenSubmit = () => {
        if (token) {
            validateToken(token);
        } else {
            setError('Please enter a valid token.');
        }
    };

    const handleTokenChange = (event) => {
        setToken(event.target.value);
    };

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Enter Token</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your token to access the dashboard.
                    </DialogContentText>
                    {error && <Alert severity="error">{error}</Alert>}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Token"
                        type="text"
                        fullWidth
                        value={token}
                        onChange={handleTokenChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTokenSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Dashboard;
