import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Card, CardContent, Grid, Box, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, TextField } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { api } from '../services/api';
import { useNavigate } from 'react-router-dom';

const TokenManagementPage = () => {
    const [token, setToken] = useState('');
    const [selectedExtension, setSelectedExtension] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('');
    const [preferences, setPreferences] = useState('');
    const [reviewLimit, setReviewLimit] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [tokenStatus, setTokenStatus] = useState('');
    const [donationAmount, setDonationAmount] = useState('10.00'); // 默认捐赠金额
    const navigate = useNavigate();

    useEffect(() => {
        const savedToken = document.cookie.split('; ').find(row => row.startsWith('token='));
        if (savedToken) {
            const tokenValue = savedToken.split('=')[1];
            setToken(tokenValue);
            checkTokenStatus(tokenValue);
            fetchTokenInfo(tokenValue);
        }
    }, []);

    const checkTokenStatus = async (token) => {
        try {
            const response = await api.checkToken(token);
            if (response.data.message === "Token exists and is not expired") {
                setTokenStatus('Token status: Active');
                setShowPaymentOptions(false);
            } else {
                setTokenStatus('Token has expired');
                setShowPaymentOptions(true);
            }
        } catch (error) {
            console.error('Error checking token', error);
            setTokenStatus('Error checking token');
        }
    };

    const fetchTokenInfo = async (token) => {
        try {
            const response = await api.getTokenInfo(token);
            setName(response.data.name || '');
            setEmail(response.data.email || '');
            setAge(response.data.age || '');
            setPreferences(response.data.preferences || '');
            setReviewLimit(response.data.review_limit || '');
        } catch (error) {
            console.error('Error fetching token information', error);
            setSnackbarMessage('Error fetching token information');
            setOpenSnackbar(true);
        }
    };

    const updateTokenInfo = async () => {
        try {
            const response = await api.updateTokenInfo(token, {
                name,
                email,
                age,
                preferences,
                review_limit: reviewLimit // 确保字段名与后端一致
            });
            setSnackbarMessage('Token information updated successfully!');
            setOpenSnackbar(true);
            setDialogOpen(false);
        } catch (error) {
            console.error('Error updating token information', error);
            setSnackbarMessage('Error updating token information');
            setOpenSnackbar(true);
        }
    };

    const handleCopyToken = () => {
        navigator.clipboard.writeText(token);
        setSnackbarMessage('Token copied to clipboard!');
        setOpenSnackbar(true);
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleLogout = () => {
        document.cookie = 'token=; Max-Age=0; path=/';
        navigate('/');
    };

    const extensionOptions = [
        { value: "3", months: "1 month" },
        { value: "15", months: "6 months" },
        { value: "30", months: "12 months" }
    ];

    const payPalOptions = {
        "client-id": "AR4YRUSK1Czd2QoJVCDej6bLdk3BH6LSFKLu288S3jc4n6k98LmazlXLtHqaibHhmelC6ljEeXPlauNp",
        "currency": "USD",
        "intent": "capture",
        "components": "buttons"
    };

    return (
        <Container maxWidth="md">
            <Box mt={5} mb={5}>
                <Typography variant="h4" gutterBottom>Token Management</Typography>
                <Card>
                    <CardContent>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="h6">Token: {token}</Typography>
                                <Button onClick={handleCopyToken}>
                                    <CopyAll />
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleOpenDialog}
                                    fullWidth
                                >
                                    Update Info
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLogout}
                                    fullWidth
                                >
                                    Logout
                                </Button>
                            </Grid>
                        </Grid>
                        {tokenStatus && (
                            <Box mt={2}>
                                <Alert severity={tokenStatus.includes('Active') ? 'success' : 'error'}>{tokenStatus}</Alert>
                            </Box>
                        )}
                        {showPaymentOptions && (
                            <Box mt={2}>
                                <Typography variant="h6" gutterBottom>Your token has expired. Please choose an option to extend your token.</Typography>
                                <Grid container spacing={3}>
                                    {extensionOptions.map((option) => (
                                        <Grid item xs={12} sm={4} key={option.value}>
                                            <Card>
                                                <CardContent>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => setSelectedExtension(option)}
                                                        fullWidth
                                                    >
                                                        Extend for {option.months} - ${option.value}
                                                    </Button>
                                                    {selectedExtension && selectedExtension.value === option.value && (
                                                        <Box mt={2}>
                                                            <PayPalScriptProvider options={payPalOptions}>
                                                                <PayPalButtons
                                                                    createOrder={(data, actions) => {
                                                                        return actions.order.create({
                                                                            purchase_units: [{
                                                                                amount: { value: option.value },
                                                                                description: `Extend token by ${option.months}`,
                                                                                custom_id: token
                                                                            }],
                                                                        });
                                                                    }}
                                                                    onApprove={(data, actions) => {
                                                                        return actions.order.capture().then(details => {
                                                                            console.log(`Payment for ${option.months} extension successful`, details);
                                                                            setSnackbarMessage('Payment successful! Token extended.');
                                                                            setOpenSnackbar(true);
                                                                            setTokenStatus('Token status: Active');
                                                                            setShowPaymentOptions(false);
                                                                        });
                                                                    }}
                                                                />
                                                            </PayPalScriptProvider>
                                                        </Box>
                                                    )}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Box>
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Update Token Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the information you want to update for the token.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Age"
                        fullWidth
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Preferences"
                        fullWidth
                        value={preferences}
                        onChange={(e) => setPreferences(e.target.value)}
                        multiline
                        rows={4}
                    />
                    <TextField
                        margin="dense"
                        label="Daily Review Word Count Limit"
                        fullWidth
                        value={reviewLimit}
                        onChange={(e) => setReviewLimit(e.target.value)}
                        type="number"
                        inputProps={{ min: "1", step: "1" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={updateTokenInfo} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarMessage.includes('successfully') ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Box mt={5}>
                <Typography variant="h6" gutterBottom>
                    We provide permanent free learning services, especially for students. If you are willing, you can support our services using the donation button. All funds received will be used for development work and the daily maintenance of the servers.
                </Typography>
                <TextField
                    label="Donation Amount"
                    value={donationAmount}
                    onChange={(e) => setDonationAmount(e.target.value)}
                    type="number"
                    inputProps={{ min: "1", step: "1" }}
                    fullWidth
                    margin="dense"
                />
                <PayPalScriptProvider options={payPalOptions}>
                    <PayPalButtons
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [{
                                    amount: { value: donationAmount },  // 使用用户指定的捐赠金额
                                    description: 'Support our free learning services'
                                }]
                            });
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then(details => {
                                alert('Donation completed by ' + details.payer.name.given_name);
                            });
                        }}
                        onError={(err) => {
                            console.error(err);
                            alert('An error occurred during the transaction');
                        }}
                    />
                </PayPalScriptProvider>
            </Box>
        </Container>
    );
};

export default TokenManagementPage;
