import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Card, CardContent, Alert } from '@mui/material';
import { api } from '../services/api';
import { saveAs } from 'file-saver';

const WordListManager = () => {
    const [file, setFile] = useState(null);
    const [wordLists, setWordLists] = useState([]);
    const [uploadResult, setUploadResult] = useState(null);

    const fetchWordLists = async () => {
        try {
            const response = await api.getWordLists();
            setWordLists(response.data);
        } catch (error) {
            console.error('Error fetching word lists', error);
        }
    };

    useEffect(() => {
        fetchWordLists();
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        formData.append('file', file);
        formData.append('token', token);

        try {
            const response = await api.uploadWordList(formData);
            setUploadResult(response.data.message);
            fetchWordLists(); // Refresh the word list
        } catch (error) {
            setUploadResult('Error uploading file');
            console.error('Error uploading file', error);
        }
    };

    const handleDownload = async (listName) => {
        try {
            const response = await api.downloadWordList(listName);
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${listName}.xlsx`);
        } catch (error) {
            console.error('Error downloading word list', error);
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Learning List Sharing
            </Typography>
            <Typography variant="body1" gutterBottom>
                Share your vocabulary lists with others and download lists shared by others.
            </Typography>
            <Box sx={{ mb: 2 }}>
                <input type="file" onChange={handleFileChange} />
                <Button variant="contained" color="primary" onClick={handleUpload} sx={{ ml: 2 }}>
                    Upload
                </Button>
            </Box>
            {uploadResult && <Alert severity="info">{uploadResult}</Alert>}
            <Box>
                {wordLists.length > 0 ? (
                    wordLists.map((list) => (
                        <Card key={list._id} sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h5">{list.list_name}</Typography>
                                <Typography color="textSecondary">
                                    Words: {list.word_count}
                                </Typography>
                                <Typography color="textSecondary">
                                    Uploader: {list.uploader || 'Anonymous Learner'}
                                </Typography>
                                <Button variant="outlined" color="primary" onClick={() => handleDownload(list.list_name)} sx={{ mt: 1 }}>
                                    Download List
                                </Button>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    <Typography>No word lists found</Typography>
                )}
            </Box>
        </Box>
    );
};

export default WordListManager;
