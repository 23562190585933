import React, { useState, useEffect } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Toolbar, CssBaseline, AppBar, Typography, Hidden, IconButton } from '@mui/material';
import { Routes, Route, NavLink, useLocation, Navigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MenuIcon from '@mui/icons-material/Menu';
import VocabularyPage from './VocabularyPage';
import TokenManagementPage from './TokenManagementPage';
import WordListManager from './WordListManager';
import Statistics from './Statistics';
import NotePage from './NotePage';

const drawerWidth = 240;

const DashboardMain = () => {
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [token, setToken] = useState('');

    useEffect(() => {
        const savedToken = document.cookie.split('; ').find(row => row.startsWith('token='));
        if (savedToken) {
            setToken(savedToken.split('=')[1]);
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    <ListItem button component={NavLink} to="/dashboard/main/vocabulary" onClick={handleDrawerToggle}>
                        <ListItemText primary="Vocabulary" />
                    </ListItem>                                                           
                    <ListItem button component={NavLink} to="/dashboard/main/notes" onClick={handleDrawerToggle}>
                        <ListItemText primary="Notes" />
                    </ListItem>
                    <ListItem button component={NavLink} to="/dashboard/main/word-list-manager" onClick={handleDrawerToggle}>
                        <ListItemText primary="Word List Manager" />
                    </ListItem> 
                    <ListItem button component={NavLink} to="/dashboard/main/statistics" onClick={handleDrawerToggle}>
                        <ListItemText primary="Statistics" />
                    </ListItem>
                    <ListItem button component={NavLink} to="/dashboard/main/tokens" onClick={handleDrawerToggle}>
                        <ListItemText primary="Settings" />
                    </ListItem>                    
                </List>
            </Box>
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Vocabulary Builder
                    </Typography>
                </Toolbar>
            </AppBar>
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <TransitionGroup>
                    <CSSTransition key={location.key} classNames="fade" timeout={300}>
                        <Routes location={location}>
                            <Route path="vocabulary" element={<VocabularyPage token={token} />} />
                            <Route path="tokens" element={<TokenManagementPage token={token} />} />
                            <Route path="word-list-manager" element={<WordListManager token={token} />} />
                            <Route path="statistics" element={<Statistics token={token} />} />
                            <Route path="notes" element={<NotePage token={token} />} />
                            <Route path="*" element={<Navigate to="vocabulary" />} />
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
            </Box>
        </Box>
    );
};

export default DashboardMain;
