import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import DashboardMain from './pages/DashboardMain';
import NavBar from './components/NavBar';
import PromptList from './components/PromptList';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
    return (
        <Router>
            <CssBaseline />
            <NavBar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/main/*" element={<DashboardMain />} />
                <Route path="/ppp" element={<PromptList />} />
            </Routes>
        </Router>
    );
}

export default App;
