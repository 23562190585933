import React, { useState, useEffect } from 'react';
import { TextField, List, Typography, Box, Snackbar, Alert } from '@mui/material';
import { api } from '../services/api';
import PromptItem from './PromptItem';
import PromptForm from './PromptForm';

const PromptList = () => {
    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        const fetchPrompts = async () => {
            try {
                const response = await api.getPrompts();
                setPrompts(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPrompts();
    }, []);

    const handleAddPrompt = async (prompt) => {
        try {
            const response = await api.createPrompt(prompt);
            setPrompts([...prompts, response.data]);
            setSnackbar({ open: true, message: 'Prompt added successfully', severity: 'success' });
        } catch (error) {
            setError(error);
            setSnackbar({ open: true, message: 'Error adding prompt', severity: 'error' });
        }
    };

    const handleUpdatePrompt = async (id, updatedPrompt) => {
        try {
            const response = await api.updatePrompt(id, updatedPrompt);
            setPrompts(prompts.map((prompt) => (prompt.id === id ? response.data : prompt)));
            setSnackbar({ open: true, message: 'Prompt updated successfully', severity: 'success' });
        } catch (error) {
            setError(error);
            setSnackbar({ open: true, message: 'Error updating prompt', severity: 'error' });
        }
    };

    const handleDeletePrompt = async (id) => {
        try {
            await api.deletePrompt(id);
            setPrompts(prompts.filter((prompt) => prompt.id !== id));
            setSnackbar({ open: true, message: 'Prompt deleted successfully', severity: 'success' });
        } catch (error) {
            setError(error);
            setSnackbar({ open: true, message: 'Error deleting prompt', severity: 'error' });
        }
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const filteredPrompts = prompts.filter((prompt) =>
        prompt.mode.toLowerCase().includes(search.toLowerCase()) ||
        prompt.prompt.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>Prompt List</Typography>
            {loading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">Error: {error.message}</Typography>}
            <TextField
                label="Search"
                variant="outlined"
                value={search}
                onChange={handleSearch}
                fullWidth
                sx={{ mb: 2 }}
            />
            <PromptForm onAddPrompt={handleAddPrompt} />
            <List>
                {filteredPrompts.map((prompt) => (
                    <PromptItem
                        key={prompt.id}
                        prompt={prompt}
                        onUpdatePrompt={handleUpdatePrompt}
                        onDeletePrompt={handleDeletePrompt}
                    />
                ))}
            </List>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PromptList;
