// PromptForm.js
import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';

const PromptForm = ({ onAddPrompt }) => {
    const [mode, setMode] = useState('');
    const [prompt, setPrompt] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddPrompt({ mode, prompt });
        setMode('');
        setPrompt('');
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
                label="Mode"
                variant="outlined"
                value={mode}
                onChange={(e) => setMode(e.target.value)}
                required
            />
            <TextField
                label="Prompt"
                variant="outlined"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                required
            />
            <Button type="submit" variant="contained" color="primary">Add Prompt</Button>
        </Box>
    );
};

export default PromptForm;
