import axios from 'axios';

export const SERVER_BASE_URL = 'https://www.vocabank.org'
const API_BASE_URL = `${SERVER_BASE_URL}/api`

export const api = {
    createToken: () => axios.post(`${API_BASE_URL}/token`),
    checkToken: (token) => axios.get(`${API_BASE_URL}/token/${token}`),
    getVocabularies: (token) => axios.get(`${API_BASE_URL}/token/${token}/vocabulary`),    
    getWordsForVocabulary: (token, vocabulary) => axios.get(`${API_BASE_URL}/token/${token}/vocabulary/${vocabulary}`),
    updateTokenInfo: (token, data) => axios.put(`${API_BASE_URL}/token/${token}/update`, data),
    getTokenInfo: (token) => axios.get(`${API_BASE_URL}/token/${token}/info`),
    addNoteToWord: (token, vocabulary, noteEntry) => axios.post(`${API_BASE_URL}/token/${token}/vocabulary/${vocabulary}/words/note`, noteEntry),
    uploadVocabulary: (token, formData) => axios.post(`${API_BASE_URL}/token/${token}/upload-vocabulary`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
    uploadImage: (formData) => axios.post(`${API_BASE_URL}/upload-image/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }),        
    uploadWordList: (formData) => axios.post(`${API_BASE_URL}/upload-word-list/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
    getWordLists: () => axios.get(`${API_BASE_URL}/word-lists/`),
    downloadWordList: (listName) => axios.get(`${API_BASE_URL}/word-lists/${listName}/download`, { responseType: 'blob' }),
    getWordsForReview: (token, vocabulary) => axios.get(`${API_BASE_URL}/token/${token}/vocabulary/${vocabulary}/review`),
    markWordAsMastered: (token, vocabulary, word) => axios.put(`${API_BASE_URL}/token/${token}/vocabulary/${vocabulary}/words/master`, { word }),
    unmarkWordAsMastered: (token, vocabulary, word) => axios.put(`${API_BASE_URL}/token/${token}/vocabulary/${vocabulary}/words/unmaster`, { word }),
    getPrompts: () => axios.get(`${API_BASE_URL}/prompts`),
    createPrompt: (prompt) => axios.post(`${API_BASE_URL}/prompts`, prompt),
    updatePrompt: (id, prompt) => axios.put(`${API_BASE_URL}/prompts/${id}`, prompt),
    deletePrompt: (id) => axios.delete(`${API_BASE_URL}/prompts/${id}`),
    getStatistics: (token) => axios.get(`${API_BASE_URL}/statistics/${token}`),
    getNotes: (token, page = 1, limit = 10, search = '') => axios.get(`${API_BASE_URL}/notes`, { params: { token, page, limit, search } }),
    createNote: (token, title, detail) => axios.post(`${API_BASE_URL}/note`, { token, title, detail }),    
    deleteWord: (token, word) => axios.delete(`${API_BASE_URL}/token/${token}/word/${word}`),
    moveWord: (token, word, newVocabulary) => axios.put(`${API_BASE_URL}/token/${token}/word/${word}/move`, newVocabulary)    
};

export const setTokenInCookie = (token) => {
    document.cookie = `token=${token}; path=/;`;
};

export const getTokenFromCookie = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; token=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};
