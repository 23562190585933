import React, { useState } from 'react';
import { ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactJson from 'react-json-view';

const PromptItem = ({ prompt, onUpdatePrompt, onDeletePrompt }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [mode, setMode] = useState(prompt.mode);
    const [promptText, setPromptText] = useState(prompt.prompt);
    const [jsonMode, setJsonMode] = useState(false);

    const handleSave = () => {
        onUpdatePrompt(prompt.id, { mode, prompt: promptText });
        setIsEditing(false);
    };

    const handleFormatJson = () => {
        try {
            const formattedJson = JSON.stringify(JSON.parse(promptText), null, 2);
            setPromptText(formattedJson);
        } catch (error) {
            alert('Invalid JSON');
        }
    };

    const handleToggleJsonMode = () => {
        if (!jsonMode) {
            // 去除HTML标签
            const plainText = promptText.replace(/<\/?[^>]+(>|$)/g, "");
            try {
                JSON.parse(plainText); // 验证是否为有效的JSON
                setPromptText(plainText);
                setJsonMode(true);
            } catch (error) {
                alert('The content is not valid JSON');
            }
        } else {
            setJsonMode(false);
        }
    };

    return (
        <ListItem>
            <ListItemText primary={`${prompt.mode}: ${prompt.prompt}`} />
            <IconButton onClick={() => setIsEditing(true)} color="primary">
                <Edit />
            </IconButton>
            <IconButton onClick={() => onDeletePrompt(prompt.id)} color="secondary">
                <Delete />
            </IconButton>

            <Dialog open={isEditing} onClose={() => setIsEditing(false)} fullWidth maxWidth="md">
                <DialogTitle>Edit Prompt</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Mode"
                        value={mode}
                        onChange={(e) => setMode(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    {jsonMode ? (
                        <ReactJson
                            src={JSON.parse(promptText)}
                            onEdit={(e) => setPromptText(JSON.stringify(e.updated_src, null, 2))}
                            onAdd={(e) => setPromptText(JSON.stringify(e.updated_src, null, 2))}
                            onDelete={(e) => setPromptText(JSON.stringify(e.updated_src, null, 2))}
                            theme="monokai"
                            style={{ padding: '20px', backgroundColor: '#f5f5f5' }}
                        />
                    ) : (
                        <ReactQuill
                            value={promptText}
                            onChange={setPromptText}
                            style={{ height: '200px', marginBottom: '20px' }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleJsonMode} color="primary">
                        {jsonMode ? 'Rich Text' : 'JSON View'}
                    </Button>
                    {jsonMode && (
                        <Button onClick={handleFormatJson} color="primary">
                            Format JSON
                        </Button>
                    )}
                    <Button onClick={() => setIsEditing(false)} color="secondary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </ListItem>
    );
};

export default PromptItem;
